import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Homepage from "../components/Homepage/Homepage";

const IndexPage = ({
  data: {
    strapiHomepage,
    strapiHomepage: { Articles: articlesData },
    allStrapiPartners,
  },
}) => {
  const panelData = strapiHomepage.amxConnectPanels;

  const featurePanelData = {
    copy: strapiHomepage.learnMore.copy,
    cta: strapiHomepage.learnMore.cta,
    heading: strapiHomepage.learnMore.heading,
    headerImage: strapiHomepage.learnMore.headerImage.localFile.publicURL,
  };

  const heroData = {
    bannerImage:
      strapiHomepage.hero.image.localFile.childImageSharp.gatsbyImageData,
    video: strapiHomepage.hero.popupVideo.video.localFile.publicURL,
    bkgVideo: strapiHomepage.hero.backgroundVideo.localFile.publicURL,
    transcript: strapiHomepage.hero.popupVideo.transcript.localFile.publicURL,
    headings: strapiHomepage.hero.headings,
    ctaText: strapiHomepage.hero.ctaText,
  };

  const workingWithData = {
    heading: strapiHomepage.workingWithHeading,
    images: allStrapiPartners.edges.map((image) => {
      let gatsbyImageData =
        image?.node?.logo?.localFile?.childImageSharp?.gatsbyImageData;

      return gatsbyImageData === undefined ? (
        <img
          src={image?.node?.logo?.localFile?.publicURL}
          alt={image?.node?.logo?.alternativeText}
        />
      ) : (
        <GatsbyImage
          image={gatsbyImageData}
          alt={image?.node?.logo?.alternativeText}
        />
      );
    }),
  };

  return (
    <Homepage
      amxConnectHeading={strapiHomepage.amxConnectHeading}
      featurePanelData={featurePanelData}
      heroData={heroData}
      articlesData={articlesData}
      panelData={panelData}
      workingWithData={workingWithData}
    />
  );
};

export default IndexPage;

export const query = graphql`
  {
    strapiHomepage {
      amxConnectPanels {
        copy
        cta {
          text
          link
        }
        title
        heading
      }

      amxConnectHeading
      workingWithHeading
      Articles {
        articlesHeading
        insights {
          id
        }
        case_studies {
          id
        }
        press_releases {
          id
        }
        events {
          id
        }
      }
      hero {
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1440
                quality: 80
                webpOptions: { quality: 80 }
              )
            }
          }
        }
        popupVideo {
          video {
            localFile {
              publicURL
            }
          }
          transcript {
            localFile {
              publicURL
            }
          }
        }
        backgroundVideo {
          localFile {
            publicURL
          }
        }
        headings {
          text
        }
        ctaText
      }

      learnMore {
        copy
        heading
        headerImage {
          localFile {
            publicURL
          }
          alternativeText
        }
        cta {
          link
          text
        }
      }
    }

    allStrapiPartners {
      edges {
        node {
          logo {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  height: 100
                  quality: 100
                  webpOptions: { quality: 100 }
                  placeholder: TRACED_SVG
                )
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`;
